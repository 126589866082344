<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="660px" title="培训回顾">
      <div class="main-content">
        <div v-for="(item, index) in fileList" class="item">
          <!-- 图片/视频 -->
          <div class="item-img">
            <div
              v-if="vedioType.includes(item.attType)"
              @click="playVideo(item.attUrl)"
            >
              <video class="img" :src="item.attUrl"></video>
            </div>
            <el-image
              v-else
              class="img"
              @click="handleImgClick(item.attUrl)"
              :src="item.attUrl"
              fit="contain"
            ></el-image>
          </div>
          <!-- 文件名 -->
          <div class="item-name">{{ item.attName }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRiskTrainReview } from "@/api/riskData.js";
export default {
  name: "TrainingReviewDialog",
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
    id: {
      type: [String, Number],
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newValue, oldValue) {
      this.dialogVisible = newValue;
    },
    dialogVisible(n, o) {
      this.$emit("update:show", n);
    },
    data(n) {
      this.fileList = n || [];
    },
    id(n) {
      getRiskTrainReview({ riskId: this.id }).then((res) => {
        this.fileList = res.data;
        this.$emit("update:show", true);
      });
    },
  },
  data() {
    return {
      videoSrc: "",
      dialogVisibleVedio: false,
      dialogVisible: false,
      fileList: [],
      vedioType: ["mp4", "avi", "rmvb", "wmv", "flv"],
    };
  },
  methods: {
    handleClose(done) {
      //this.fileList = [];
    },
    handleImgClick(url) {
      window.open(url);
    },
    playVideo(url) {
      window.open(url);
      // this.videoSrc = url;
      // this.dialogVisibleVedio = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  display: flex;
  flex-wrap: wrap;
  //padding: 16px;
  .item {
    margin-right: 16px;
    margin-top: 16px;
    &-img {
      width: 185px;
      height: 115px;
      border-radius: 4px;
    }
    &-name {
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      max-width: 185px;
      //  省略文字
      overflow: hidden;
    }
  }
}
.img {
  width: 185px;
  height: 115px;
  border-radius: 4px;
}
</style>
