<template>
  <div class="main-container">
    <!-- 导航栏 -->
    <div class="navigation-bar">
      <el-button class="back-btn" icon="el-icon-back" @click="back"
        >返回</el-button
      >
      <el-breadcrumb style="margin-left: 16px" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          ><a href="/#/riskHomeManagement">风险管理</a></el-breadcrumb-item
        >
        <el-breadcrumb-item>风控培训</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 筛选 -->
    <div class="filter">
      <el-form
        label-width="90px"
        :inline="true"
        :model="searchParams"
        class="demo-form-inline"
        v-if="!isFold"
      >
        <el-form-item label="培训类型">
          <el-input
            v-model="searchParams.trainingType"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="培训地点">
          <el-input
            v-model="searchParams.trainingSite"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="培训日期">
          <div>
            <el-date-picker
              v-model="searchParams.trainingDate"
              placeholder="请选择日期"
            >
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-select
            v-model="searchParams.projectId"
            clearable
            placeholder="请选择"
            @change="changeProject"
          >
            <el-option
              v-for="item in dictList.projectList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey + ''"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-select
            v-model="searchParams.userId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList.customerList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="getData"> 查询</el-button
        ><el-button @click="resetData"> 重置</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="table-action">
        <div>
          <el-button @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <div class="flex align-center">
          <!-- <img
            @click="isFold = !isFold"
            style="width: 30px; height: 30px; cursor: pointer"
            src="@/assets/images/RiskControl/cl.png"
          /> -->
          <div @click="handleExportData" class="export">导出</div>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-class-name="headerRowClassName"
        max-height="390"
      >
        <template slot="empty">
          <el-empty
            description="列表暂无数据"
            :image="require('@/assets/images/RiskControl/risk-empty.png')"
          ></el-empty>
        </template>
        <el-table-column type="index" width="55"> </el-table-column>
        <el-table-column prop="recordNo" label="风控编号">
          ><template slot-scope="{ row }">{{
            row.recordNo || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="trainingType" label="培训类型">
          ><template slot-scope="{ row }">{{ row.trainingType || "---" }}</template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称">
          <template slot-scope="{ row }">{{
            row.customerName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="trainingDate" label="培训日期">
          ><template slot-scope="{ row }">{{
            row.trainingDate || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="fullAddress" label="培训地点">
          ><template slot-scope="{ row }">{{
            row.fullAddress || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="participationNumber" label="参与人数">
          ><template slot-scope="{ row }">{{
            row.participationNumber || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="trainingSatisfaction" label="客户满意度">
          ><template slot-scope="{ row }">
            <el-rate disabled v-model="row.trainingSatisfaction"></el-rate>
          </template>
        </el-table-column>
        <el-table-column prop="teacherName" label="培训人员">
          ><template slot-scope="{ row }">{{
            row.teacherName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称">
          ><template slot-scope="{ row }">{{
            row.projectName || "---"
          }}</template>
        </el-table-column>
        <el-table-column prop="expenditure" label="费用开销">
          ><template slot-scope="{ row }">{{
            row.expenditure || "---"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }"
            ><el-button @click="handleReview(row)" type="text"
              >培训回顾</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">共计{{ searchParams.total || 0 }}条记录</div>
        <el-pagination
          background
          layout="sizes,prev,pager,next,jumper"
          :total="searchParams.total"
          :current-page.sync="searchParams.current"
          :page-size="searchParams.size"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <TrainingReviewDialog
      :data="reviewData.trainingReview || []"
      :show.sync="dialogVisible"
    ></TrainingReviewDialog>
  </div>
</template>

<script>
import {
  getProjectCustomer,
  getRiskTrainDetailList,
  getRiskTrainReview,
  exportRiskTrain,
} from "@/api/riskData.js";
import { insClaimsCasePage, insClaimsCaseExportAll } from "@/api/claimData.js";
import { dictionaryBatch } from "@/api/policy";
import { userProject } from "@/api/SystemData";
import dayjs from "dayjs";
import TrainingReviewDialog from "../components/TrainingReviewDialog.vue";
import { trainingRecordDetail } from "@/api/basicData";
export default {
  components: { TrainingReviewDialog },
  data() {
    return {
      isFold: false,
      dialogVisible: false,
      reviewData: [],
      dictList: {
        customerList: [],
        dangerTypeList: [],
        projectList: [],
      },
      tableData: [],
      searchParams: {
        current: 1,
        size: 10,
        total: 0,
        projectId: "",
        userId: "",
        trainingSite: "",
        trainingType: "",
        trainingDate: "",
      },
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  methods: {
    async init() {
      //this.searchParams.date = dayjs().format("YYYY-MM-DD");
      this.searchParams = { ...this.searchParams, ...this.$route.query };
      await this.getDictionaryBatch();
      this.getData();
    },
    handleExportData() {
      exportRiskTrain({
        ...this.searchParams,
      });
    },
    handleSizeChange(e) {
      this.searchParams.size = e;
      this.getData();
    },
    handleCurrentChange(e) {
      this.searchParams.current = e;
      this.getData();
    },
    getData() {
      this.searchParams.trainingDate &&
        (this.searchParams.trainingDate = dayjs(
          this.searchParams.trainingDate
        ).format("YYYY-MM-DD"));
      getRiskTrainDetailList(this.searchParams).then((res) => {
        this.tableData = res.data.records;
        this.searchParams.total = res.data.total;
      });
    },
    async getDictionaryBatch() {
      dictionaryBatch({
        codes: "dangerType",
      }).then((res) => {
        this.dictList.dangerTypeList = res.data.dangerType || [];
      });
      let proRes = await userProject({
        userId: this.$store.state.userInfo.MJUserId,
      });
      if (proRes.data.length > 0) {
        this.dictList.projectList = proRes.data.map((item) => {
          return {
            dictKey: item.projectId,
            dictValue: item.projectName,
          };
        });
        this.getCustomerList();
      }
    },
    getCustomerList() {
      this.dictList.customerList = [];
      this.searchParams.userId = "";
      getProjectCustomer({ projectId: this.searchParams.projectId }).then(
        (res) => {
          if (res.data.records) {
            this.dictList.customerList = res.data.records.map((item) => {
              return {
                dictKey: item.customerUserId,
                dictValue: item.customerName,
              };
            });
          } else {
            this.dictList.customerList = [];
          }
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "zdy-row";
      }
    },
    headerRowClassName() {
      return "zdy-header-row";
    },
    back() {
      this.$router.back();
    },
    resetData() {
      this.searchParams = {
        current: 1,
        size: 10,
        total: 0,
        projectId: "",
        userId: "",
        trainingSite: "",
        trainingType: "",
        trainingDate: "",
        ...this.$route.query,
      };
      this.getCustomerList();
      this.getData();
    },
    changeProject(e) {
      //console.log(e);
      this.getCustomerList();
    },
    async handleReview(item) {
      try {
        let {code, data} = await trainingRecordDetail(item.id);
        if (code == 200) {
          this.reviewData = data;
          this.dialogVisible = true;
        }
      } catch (error) {
        
      }
      // getRiskTrainReview({ riskId: item.id })
      //   .then((res) => {
      //     this.reviewData = res.data;
      //     this.dialogVisible = true;
      //   })
      //   .catch(() => {
      //     this.reviewData = [];
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  width: 260px;
}
::v-deep .el-input__inner {
  width: 100%;
}
::v-deep .el-select {
  width: 100%;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.main-container {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  height: 100%;
  .navigation-bar {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  .filter {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    padding: 16px 15px;
  }
  .table {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 12px;
    margin-top: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    &-action {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .total {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
.chart {
  width: 100%;
  flex: 1;
}
.export {
  background: #f5f5f5;
  border-radius: 4px 4px 4px 4px;
  padding: 8px 13px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-left: 16px;
  cursor: pointer;
}
.point {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.back-btn {
  padding: 7px 12px;
  background: #4278c9;
  border-radius: 17px 17px 17px 17px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
.zdy-header-row {
  background-color: #f2f7fd !important;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
</style>
